.App {
  /*text-align: center;*/
  background-color: black;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FBFEF9;
}

.App-link {
  color: #61dafb;
}

.game_markdown_yellow {
  color: olive;
}

.game_markdown_light_green {
  color: lightgreen;
}

.game_markdown_green {
  color: #4D8B31;
}

.game_markdown_red_on_black {
  color: red;
}

.game_markdown_yellow_bold {
  color: olive;
  font-weight: bold;
}

.game_markdown_purple {
  color: #EF798A;
}

.game_markdown_bright_blue_bold {
  color: blue;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

pre {
  line-height: normal;
  background: #003200;
}

blockquote {
  line-height: normal;
  background: #240024;
}
